<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="create-social-security-modal" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Update Social Security Office Registration") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create Social Security Office Registration") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">{{ $t('Cancel') }}</b-button>
          </div>
        </div>
      </template>

      <legal-person-social-security-form :default-country="legalPerson.country_of_establishment"
        :existing-form="existingForm" @input="onFormInput"></legal-person-social-security-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import LegalPersonSocialSecurityForm from "@/view/components/forms/legal-person/LegalPersonSocialSecurityForm.vue";
import CompanySocialSecurity from "@/core/services/company/company-socialsecurity.service";
import icons from "@/core/config/icons";
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";

export default {
  components: {
    LegalPersonSocialSecurityForm,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    existingForm: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      form: null,
      isSaving: false,
      apiErrors: null,
      icons,
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    }
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {};
        for (const key in this.form) {
          newErrors[key] = [...(val[key] ?? [])];
        }
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('create-social-security-modal');
    },

    onModalHidden() {
      this.form = null;
    },

    async submit() {
      this.isSaving = true;
      try {
        if (this.isEdit) {
          await CompanySocialSecurity.update(this.form, this.form.id)
        } else {
          await CompanySocialSecurity.create({ ...this.form, company: this.legalPerson.id });
        }
        this.$emit("refresh");
        this.closeModal();
        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.")
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the document. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    onFormInput(f) {
      this.form = f;
    },
  },
};
</script>
