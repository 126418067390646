<template>
  <div>
    <datatable ref="datatableRef" :table-props="tableProps" :total="total" :fields="fields" :options.sync="tableOptions"
      :per-page-options="perPageOptions">
      <template #[`cell.actions`]="{ item }">
        <div class="d-flex">
          <b-button v-b-tooltip.hover :disabled="!item.attachment" :title="$t('Download attachment')"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="download(item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Clip.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
            @click="onEdit(item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Design/Edit.svg" />
            </span>
          </b-button>
          <b-button v-b-tooltip.hover :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
            @click="remove(item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </b-button>
        </div>
      </template>

      <template #[`cell.country`]="{ item }">
        <country-flag :country-iso="item.country"></country-flag>
      </template>


      <template #[`cell.from_date`]="{ item }">
        <span class="font-weight-bolder text-muted">{{ item.from_date ? DateService.format(item.from_date, dateFormat) ??
          "" : "" }}</span>
      </template>

      <template #[`cell.to_date`]="{ item }">
        <span class="font-weight-bolder text-muted">{{ item.to_date ? DateService.format(item.to_date, dateFormat) ?? "" :
          "" }}</span>
      </template>
    </datatable>
    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
      <b-modal ref="editModal" hide-footer size="lg" @hidden="onModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>{{ $t("Edit Social Security") }}</h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2"
                :class="{ 'spinner spinner-light spinner-right': editModal.isSaving }" @click="handleSubmit(update)">
                {{ $t("Save Changes") }}
              </b-button>
              <b-button @click="closeModal">
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </template>
        <legal-person-social-security-form :existing-form="editModal.item"
          @input="onLegalPersonSocialSecurityFormInput"></legal-person-social-security-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import Datatable from "@/view/components/tables/Datatable.vue";
import LegalPersonSocialSecurityForm from "@/view/components/forms/legal-person/LegalPersonSocialSecurityForm.vue";
import { mapGetters, mapState } from "vuex";
import DateService from "@/core/services/date.service";
import { deleteSwal, backendErrorSwal } from "@/core/helpers/swal";
import CountryFlag from "@/view/components/CountryFlag.vue";
import CompanySocialSecurity from "@/core/services/company/company-socialsecurity.service";
import Swal from "sweetalert2";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    Datatable,
    LegalPersonSocialSecurityForm,
    CountryFlag,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    /**
     * Administrative structures.
     */
    fetchType: {
      type: String,
      default: "all",
    },
    id: {
      type: [String, Number],
      default: -1,
    },
  },

  data() {
    return {
      DateService,
      icons,
      fields: [
        {
          label: this.$t("Country"),
          key: "country",
          sortable: true
        },
        {
          label: this.$t("Number"),
          key: "number",
          sortable: true,
          class: ["align-middle", "font-weight-bolder"],
        },
        {
          label: this.$t("From Date"),
          key: "from_date",
          sortable: true
        },
        {
          label: this.$t("To Date"),
          key: "to_date",
          sortable: true
        },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      editModal: {
        item: null,
        isSaving: false,
        apiErrors: null,
        isDownloading: false,
      },
    };
  },
  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
      };
    },
  },
  watch: {
    "editModal.apiErrors": {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {};
        for (const key in this.editModal.item) {
          newErrors[key] = [...(val[key] ?? [])];
        }
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    itemProvider(ctx, callback) {
      if (this.fetchType === "all") {
        CompanySocialSecurity.getAllPaginated(this.legalPerson.id, {
          perPage: ctx.perPage,
          page: ctx.currentPage,
          sortBy: ctx.sortBy || "id",
          search: ctx.filter,
          sortDesc: ctx.sortDesc,
          fields: 'id,attachment,country,from_date,to_date,number'
        }).then(({ count, results }) => {
          this.total = count;
          callback(results);
        }).catch(() => callback([]));
      } else {
        CompanySocialSecurity.getAllPaginated(this.id, {
          fields: 'id,attachment,country,from_date,to_date,number'
        }).then((res) => {
          const results = [res];
          this.total = 1;
          callback(results);
        }).catch(() => callback([]));
      }
      return null;
    },

    onEdit(item) {
      this.editModal.item = { ...item };
      this.$refs.editModal.show();
    },

    closeModal() {
      this.$refs.editModal.hide();
    },

    onModalHidden() {
      this.editModal.apiErrors = null;
      this.editModal.item = null;
    },

    async update() {
      this.editModal.isSaving = true;
      try {
        await CompanySocialSecurity.update(this.editModal.item, this.editModal.item.id);
        this.refresh();
        this.closeModal();
        let title = this.$t("Document Updated")
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (typeof err == "object") {
          this.editModal.apiErrors = err;
          return;
        }
        backendErrorSwal(err);
      } finally {
        this.editModal.isSaving = false;
      }
    },

    async remove(id) {
      deleteSwal().then(async (res) => {
        if (res.isConfirmed) {
          try {
            await CompanySocialSecurity.delete(id);
            this.refresh();
          } catch (err) {
            backendErrorSwal(err);
          }
        }
      });
    },

    async download(id) {
      try {
        this.editModal.isDownloading = true;
        const res = await CompanySocialSecurity.getOne(id);
        window.open(res.attachment, "_blank");
      } catch (err) {
        backendErrorSwal(err);
      } finally {
        this.editModal.isDownloading = false;
      }
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    onLegalPersonSocialSecurityFormInput(f) {
      this.editModal.item = f;
    },
  },
};
</script>
