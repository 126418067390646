<template>
  <form autocomplete="off">
    <div class="form row">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="nation" rules="required">
          <euro-select v-model="form.country" :error-messages="errors" :label="`${$t('Country')}*`"
            :options="countriesSelect" searchable></euro-select>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From Date')" rules="required">
          <date-picker v-model="form.from_date" :overrides="fromDateOverrides" :error-messages="errors"
            placeholder="YYYY-MM-DD" :label="`${$t('From Date')}*`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('To Date')" vid="to_date">
          <date-picker v-model="form.to_date" :overrides="toDateOverrides" :error-messages="errors"
            :label="`${$t('To Date')}`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="`${$t('Number')}`" vid="number" rules="required">
          <euro-input v-model="form.number" :label="`${$t('Number')}*`" :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" class="w-100">
          <div id="attachmentFile">
            <euro-tus-file-input :label="`${$t('Attachment')}*`" :error-messages="errors"
              :disabled="!form?.country || !form?.number?.length" accept="application/pdf"
              :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment"
              @change="onAttachmentChange"></euro-tus-file-input>
            <b-tooltip v-if="!form?.country || !form?.number?.length" target="attachmentFile">
              {{ $t("Enter the above required data first") }}
            </b-tooltip>
          </div>
        </validation-provider>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import moment from "moment";
import { mapState } from "vuex";
import { countryChangeSwal } from "@/core/helpers/swal";
const now = new Date();

export default {
  components: {
    DatePicker,
  },

  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    defaultCountry: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      maxDate: now,
      form: {
        country: "",
        number: "",
        from_date: null,
        to_date: null,
        attachment_tus: null,
      },
      companies: [],
      loading: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    companySelectable() {
      return this.companies.map((el) => ({
        value: el.id,
        text: `${el.name} - ${el.country_of_establishment}`,
      }));
    },

    toDateOverrides() {
      return this.form.from_date !== "Invalid date" ? { minDate: this.form.from_date } : null;
    },

    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },
  },

  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },

    defaultCountry: {
      immediate: true,
      handler(val) {
        if (val && !this.form.country) this.form.country = val;
      },
    },
    'form.country': {
      handler(newVal, oldVal) {
        //control the swal for dont do loop
        if (this.isChangingCountry) {
          this.isChangingCountry = false;
          return;
        }
        if (newVal !== oldVal && this.form.country !== this.existingForm.country && newVal !== null) {
          countryChangeSwal({
            text: this.$t("You are about to change the country. Are you sure?"),
          }).then(res => {
            if (!res.isConfirmed) {
              this.isChangingCountry = true;
              this.form.country = oldVal;
            }
          });
        }
      },
      inmediate: true
    },
  },

  async mounted() {
    this.loading = true;
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
    this.loading = false;
  },

  methods: {
    onAttachmentChange(f) {
      this.form.attachment_tus = f;
    },
  },
};
</script>
