import api from "../api.service";

class CompanySocialSecurity {
  async getAllPaginated(companyId, {
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      company: companyId
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }

    const res = await api.get("company-socialsecuritycertificate", {
      params,
    });
    return res.data;
  }

  async getOne(id) {
    const res = await api.get(`company-socialsecuritycertificate/${id}/`);
    return res.data;
  }

  async create(form) {
    const res = await api.post("company-socialsecuritycertificate/", form);
    return res.data;
  }

  async update(form, id) {
    const res = await api.patch(`company-socialsecuritycertificate/${id}/`, form);
    return res.data;
  }

  async delete(id) {
    await api.delete(`company-socialsecuritycertificate/${id}/`);
  }
}

export default new CompanySocialSecurity();